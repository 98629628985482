import axios from "axios"
let vlogin_id = localStorage.getItem('login_id');
class UploadFilesService {
    upload(fileObj, onUploadProgress,parameter) {
      vlogin_id = localStorage.getItem('login_id');

      let formData = new FormData();
      //console.log(parameter);
      formData.append("file", fileObj);
      formData.append("login_id", vlogin_id);
      formData.append("file_type", parameter['file_type']);
      formData.append("route_type", parameter['route_type']);

      // let formData = {
      //   "file":fileObj,
      //   "login_id":vlogin_id,
      //   "file_type":parameter['file_type'],
      //   "route_type":parameter['route_type']
      // }
      // //console.log(fileObj);
  
      return axios.post("ap_file/file_upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        },
        onUploadProgress
      });
    }
  
    getFiles() {
      return axios.get("/files");
    }
}

export default new UploadFilesService();