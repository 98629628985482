import axios from "axios"
let vlogin_id = localStorage.getItem('login_id');
class ApMerchantService {


    list_merchant() {
        vlogin_id = localStorage.getItem('login_id');
        let form = {login_id:vlogin_id};
        return axios.post("ap_merchant/list_merchant", form);

    }
    
    view_merchant(form) {
        vlogin_id = localStorage.getItem('login_id');
        form.login_id = vlogin_id;
        return axios.post("ap_merchant/view_merchant", form);

    }

    insert_merchant(form) {
        vlogin_id = localStorage.getItem('login_id');
        form.login_id = vlogin_id;
        return axios.post("ap_merchant/insert_merchant", form);

    }

    update_merchant(form) {
        vlogin_id = localStorage.getItem('login_id');
        form.login_id = vlogin_id;
        return axios.post("ap_merchant/update_merchant", form);

    }    

    
    list_merchant_outlet(form) {
        vlogin_id = localStorage.getItem('login_id');
        form.login_id = vlogin_id;
        return axios.post("ap_merchant_outlet/list_merchant_outlet", form);

    }

    view_merchant_outlet(form) {
        vlogin_id = localStorage.getItem('login_id');
        form.login_id = vlogin_id;
        return axios.post("ap_merchant_outlet/view_merchant_outlet", form);
    }
    
    update_merchant_outlet(form) {
        vlogin_id = localStorage.getItem('login_id');
        form.login_id = vlogin_id;
        return axios.post("ap_merchant_outlet/update_merchant_outlet", form);
    }
    insert_merchant_outlet(form) {
        vlogin_id = localStorage.getItem('login_id');
        form.login_id = vlogin_id;
        return axios.post("ap_merchant_outlet/insert_merchant_outlet", form);

    }
    ///////////////////////////////////////////////////////////////////////////
        
    list_merchant_edc(form) {
        vlogin_id = localStorage.getItem('login_id');
        form.login_id = vlogin_id;
        return axios.post("ap_merchant_edc/list_merchant_edc", form);

    }

    view_merchant_edc(form) {
        vlogin_id = localStorage.getItem('login_id');
        form.login_id = vlogin_id;
        return axios.post("ap_merchant_edc/view_merchant_edc", form);
    }
    
    update_merchant_edc(form) {
        vlogin_id = localStorage.getItem('login_id');
        form.login_id = vlogin_id;
        return axios.post("ap_merchant_edc/update_merchant_edc", form);
    }
    
    insert_merchant_edc(form) {
        vlogin_id = localStorage.getItem('login_id');
        form.login_id = vlogin_id;        
        //console.log(form);
        return axios.post("ap_merchant_edc/insert_merchant_edc", form);

    }

    list_merchant_user(form) {
        vlogin_id = localStorage.getItem('login_id');
        form.login_id = vlogin_id;
        return axios.post("ap_merchant_user/list_merchant_user", form);

    }

    view_merchant_user(form) {
        vlogin_id = localStorage.getItem('login_id');
        form.login_id = vlogin_id;
        return axios.post("ap_merchant_user/view_merchant_user", form);
    }
    
    update_merchant_user(form) {
        vlogin_id = localStorage.getItem('login_id');
        form.login_id = vlogin_id;
        return axios.post("ap_merchant_user/update_merchant_user", form);
    }
    
    insert_merchant_user(form) {
        vlogin_id = localStorage.getItem('login_id');
        form.login_id = vlogin_id;
        //console.log(form);
        return axios.post("ap_merchant_user/insert_merchant_user", form);

    }    

    list_user_group(form) {
        vlogin_id = localStorage.getItem('login_id');
        form.login_id = vlogin_id;
        return axios.post("ap_merchant_user/list_user_group", form);
    }

    insert_user_group(form) {
        vlogin_id = localStorage.getItem('login_id');
        form.login_id = vlogin_id;
        return axios.post("ap_merchant_user/insert_user_group", form);
    }        

    update_user_group(form) {
        vlogin_id = localStorage.getItem('login_id');
        form.login_id = vlogin_id;
        return axios.post("ap_merchant_user/update_user_group", form);
    }    

    view_user_group(form) {
        vlogin_id = localStorage.getItem('login_id');
        form.login_id = vlogin_id;
        return axios.post("ap_merchant_user/view_user_group", form);
    }    

    search_request(form) {
        vlogin_id = localStorage.getItem('login_id');
        form.login_id = vlogin_id;
        return axios.post("ap_merchant_reimbursement/search_request", form);
    }    

    view_request(form) {
        vlogin_id = localStorage.getItem('login_id');
        form.login_id = vlogin_id;
        return axios.post("ap_merchant_reimbursement/view_request", form);
    } 
    
    update_request(form) {
        vlogin_id = localStorage.getItem('login_id');
        form.login_id = vlogin_id;
        return axios.post("ap_merchant_reimbursement/update_request", form);
    }

    insert_request_status(form) {
        vlogin_id = localStorage.getItem('login_id');
        form.login_id = vlogin_id;
        return axios.post("ap_merchant_reimbursement/insert_request_status", form);
    }
    

}

export default new ApMerchantService();