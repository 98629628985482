<template>
    <v-container>
        <DlgMessage ref="dlgMsg" @dlgClose="dlgClose"></DlgMessage>
        <DlgImageMedia ref="dlgImageMedia" :visible="dlgVisibleImageMedia" :route_type="cpFiles.route_type" :section="dlgImageMediaSection" @close="dlgImageMediaClose"></DlgImageMedia>
        <upload-files :visible="cpFiles.showDialog" :file_type="cpFiles.file_type" :route_type="cpFiles.route_type" :caller="caller" @upload-done="uploadDone" @close="cpFiles.showDialog=false"></upload-files>

        <div class="mb-3">
          <v-toolbar>
            <v-btn icon class="hidden-xs-only" @click="$router.back()"><v-icon>mdi-arrow-left</v-icon></v-btn>
            <v-toolbar-title>{{ this.routeParam.outlet_name }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-breadcrumbs :items="items"></v-breadcrumbs>
          </v-toolbar>
        </div> 
        <v-row class="mt-3">
           <v-col cols="12" md="12">
                <v-toolbar dark color="panel1"><v-toolbar-title>Info</v-toolbar-title>
                    <v-spacer></v-spacer>
                <v-btn icon dark @click="save"><v-icon>save</v-icon></v-btn>
            </v-toolbar>             
                <v-card class="mt-5">
                <v-form ref="mainForm">
                    <v-container class="py-0">
                        <v-row>
                            <v-col cols="12" md="12">
                                <v-text-field v-model="form.merchant_outlet_id" disabled label="Outlet ID" :rules="inputRules" outlined></v-text-field>
                                <v-text-field v-model="form.merchant_name" disabled label="Merchant Name" outlined></v-text-field>                                

                                <v-text-field v-model="form.outlet_code" label="Code" :rules="inputRules" outlined></v-text-field>
                                <v-text-field v-model="form.outlet_name" label="Name" :rules="inputRules" outlined></v-text-field>
                                <v-select
                                    v-model = "form.outlet_type_code"
                                    :items="outlet_type_list"
                                    item-text="description"
                                    item-value="code"
                                    label="Type"
                                    standard
                                    :rules="inputRules"
                                     outlined
                                ></v-select>
                                <v-select
                                    v-model = "form.status"
                                    :items="status_list"
                                    item-text="description"
                                    item-value="code"
                                    label="Status"
                                    standard
                                    :rules="inputRules"
                                     outlined
                                ></v-select>
                            <v-menu v-model="dpicker.menu1" :close-on-content-click="false" max-width="290">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        outlined
                                        dense
                                        :value="form.start_date"
                                        label="Start Date"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker v-model="form.start_date" @change="dpicker.menu1 = false"></v-date-picker>
                            </v-menu>
                            <v-menu v-model="dpicker.menu2" :close-on-content-click="false" max-width="290">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        outlined
                                        dense
                                        :value="form.end_date"
                                        label="End Date"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker v-model="form.end_date" @change="dpicker.menu2 = false"></v-date-picker>
                            </v-menu>                            </v-col>
                        </v-row>

                    </v-container>
                </v-form>

                </v-card>
                <v-toolbar dark  color="panel2"><v-toolbar-title>Address</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon dark @click="save"><v-icon>save</v-icon></v-btn>
                </v-toolbar>             
                <v-card class="mt-5">
                <v-form ref="mainForm">
                    <v-container class="py-0">
                        <v-row>
                            <v-col cols="12" md="12">
                                <v-text-field v-for="address in addresses" :key="address.label" :label="address.label" v-model="form[address.vmodel]" :rules="address.rules" outlined></v-text-field>
                                <v-select
                                    v-model = "form.state"
                                    :items="state_list"
                                    item-text="description"
                                    item-value="code"
                                    label="State"
                                    standard
                                    :rules="inputRules"
                                     outlined
                                ></v-select>
                            </v-col>
                        </v-row>

                    </v-container>
                </v-form>

                </v-card>
                <v-toolbar dark  color="panel3"><v-toolbar-title>Contact</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon dark @click="save"><v-icon>save</v-icon></v-btn>
                </v-toolbar>             
                <v-card class="mt-5">
                <v-form ref="mainForm">
                    <v-container class="py-0">
                        <v-row>
                            <v-col cols="12" md="12">
                                <v-text-field v-for="contact in contacts" :key="contact.label" :label="contact.label" v-model="form[contact.vmodel]" :rules="contact.rules" outlined></v-text-field>
                            </v-col>
                        </v-row>

                    </v-container>
                </v-form>

                </v-card>

            </v-col>
            <!--image-->
            <v-col cols="12" md="12">
                <v-toolbar dark color="panelImage"><v-toolbar-title>Images</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon dark @click="save"><v-icon>save</v-icon></v-btn>
                </v-toolbar>             
                <v-card class="mt-5">
                    <div class="d-flex flex-no-wrap">
                        <v-avatar class="ma-3" size="125" tile>
                            <v-img v-if="form.logo_file_name" :src="this.form.file_url + this.form.logo_file_name"></v-img>
                            <v-img v-else src="../../assets/image/no-image.png"></v-img>
                        </v-avatar>
                        
                        <div>
                            <v-card-title class="headline">Logo</v-card-title>
                            <v-card-subtitle>{{ form.logo_file_name}}</v-card-subtitle>
                            <v-card-actions>
                                <v-btn class="ml-2 mt-5 btnUpload" dark @click="showUpload('form.logo_file_name')"><v-icon>mdi-plus</v-icon>Upload</v-btn>
                                <v-btn class="ml-2 mt-5 btnLibrary" dark @click="showDlgImageMedia('logo_file_name')"><v-icon>mdi-folder-image</v-icon>Library</v-btn>
                            </v-card-actions>
                        </div>
                        
                    </div>
                </v-card>
                <v-card class="mt-5">
                    <div class="d-flex flex-no-wrap">
                        <v-avatar class="ma-3" size="125" tile>
                            <!--v-img :src="item.src"></v-img-->
                            <v-img v-if="form.display_pic1" :src="this.form.file_url + this.form.display_pic1"></v-img>
                            <v-img v-else src="../../assets/image/no-image.png"></v-img>
                        </v-avatar>
                        
                        <div>
                            <v-card-title class="headline">Picture 1</v-card-title>
                            <v-card-subtitle>{{ form.display_pic1}}</v-card-subtitle>
                            <v-card-actions>
                                <v-btn class="ml-2 mt-5 btnUpload" dark @click="showUpload('form.display_pic1')"><v-icon>mdi-plus</v-icon>Upload</v-btn>
                                <v-btn class="ml-2 mt-5 btnLibrary" dark @click="showDlgImageMedia('display_pic1')"><v-icon>mdi-folder-image</v-icon>Library</v-btn>

                            </v-card-actions>
                        </div>
                        
                    </div>
                </v-card>
                <v-card class="mt-5">
                    <div class="d-flex flex-no-wrap">
                        <v-avatar class="ma-3" size="125" tile>
                            <!--v-img :src="item.src"></v-img-->
                            <v-img v-if="form.display_pic2" :src="this.form.file_url + this.form.display_pic2"></v-img>
                            <v-img v-else src="../../assets/image/no-image.png"></v-img>
                        </v-avatar>
                        
                        <div>
                            <v-card-title class="headline">Picture 2</v-card-title>
                            <v-card-subtitle>{{ form.display_pic2}}</v-card-subtitle>
                            <v-card-actions>
                                <v-btn class="ml-2 mt-5 btnUpload" dark @click="showUpload('form.display_pic2')"><v-icon>mdi-plus</v-icon>Upload</v-btn>
                                <v-btn class="ml-2 mt-5 btnLibrary" dark @click="showDlgImageMedia('display_pic2')"><v-icon>mdi-folder-image</v-icon>Library</v-btn>
                            </v-card-actions>
                        </div>
                        
                    </div>
                </v-card>
                <v-card class="mt-5">
                    <div class="d-flex flex-no-wrap">
                        <v-avatar class="ma-3" size="125" tile>
                            <!--v-img :src="item.src"></v-img-->
                            <v-img v-if="form.display_pic3" :src="this.form.file_url + this.form.display_pic3"></v-img>
                            <v-img v-else src="../../assets/image/no-image.png"></v-img>
                        </v-avatar>
                        
                        <div>
                            <v-card-title class="headline">Picture 3</v-card-title>
                            <v-card-subtitle>{{ form.display_pic3}}</v-card-subtitle>
                            <v-card-actions>
                                <v-btn class="ml-2 mt-5 btnUpload" dark @click="showUpload('form.display_pic3')"><v-icon>mdi-plus</v-icon>Upload</v-btn>
                                <v-btn class="ml-2 mt-5 btnLibrary" dark @click="showDlgImageMedia('display_pic3')"><v-icon>mdi-folder-image</v-icon>Library</v-btn>

                            </v-card-actions>
                        </div>
                        
                    </div>
                </v-card> 

                    <div class="mt-3">
                        
                    </div>
                
            </v-col>

        </v-row>

    </v-container>  
</template>
    
<script>
import DlgMessage from '@/components/DlgMessage'
import DlgImageMedia from '@/components/DlgImageMedia'
import UploadFiles from "@/components/UploadFiles";
import ApMerchantService from "@/services/ApMerchantService"
import moment from 'moment'
export default {
  components:{DlgMessage,UploadFiles,DlgImageMedia},
  props: {
       caller: String
    },
  data() {
    return{
            //breadcrumb
            items: [
              {text:'Merchants',disabled: false,href:'/merchants/',},
              {text:'Outlet',disabled: false,href:'/merchants/',},
              {text:'Details',disabled: false,href:'#',},
              ],

            outlet_type_list:[],
            status_list:[],
            state_list:[],
            routeParam:[],
            form:{login_id:null,merchant_outlet_id:0},
            addresses:[
                {label:"Address 1",vmodel:"address1",rules:this.inputRules},
                {label:"Address 2",vmodel:"address2",rules:this.inputRules},
                {label:"Address 3",vmodel:"address3",rules:this.inputRules},
                {label:"City",vmodel:"city",rules:this.inputRules},
                {label:"Postal Code",vmodel:"postal_code",rules:this.inputRules},
            ],            
            contacts:[
                {label:"Contact Person",vmodel:"contact_person",rules:this.inputRules},
                {label:"Work Phone",vmodel:"work_phone",rules:this.inputRules},
                {label:"Cell Phone",vmodel:"cell_phone",rules:this.inputRules},
                {label:"Fax",vmodel:"fax_phone",rules:this.inputRules},
                {label:"Email",vmodel:"email",rules:this.inputRules},
            ],

            logo:'',
            encMerchantName:'',

            inputRules: [
             v => !!v || 'This field is required'
            ],

            cpFiles:{
                file_type:'image',
                route_type:'merchants',
                showDialog:false,
            },
            dpicker:{menu1: false,menu2: false,},
            dlgVisibleImageMedia:false,           
            dlgImageMediaSection:"",

    }
  },
  async created() {
    this.form.start_date = moment(String(new Date())).format('YYYY-MM-DD');
    this.form.end_date = moment(String(new Date())).format('YYYY-MM-DD');

    this.routeParam['merchant_id'] = this.$route.params.merchant_id;
    this.routeParam['merchant_name'] = atob(this.$route.params.enc_merchant_name);
    this.routeParam['outlet_id'] = this.$route.params.outlet_id;
    this.routeParam['outlet_name'] = atob(this.$route.params.enc_outlet_name);
    this.viewDetail();

  },
  
  methods:{

    async viewDetail(){
        this.form.merchant_outlet_id = this.routeParam.outlet_id
        try{
            ApMerchantService.view_merchant_outlet(this.form).then((res) => {
                this.form = res.data.data;
                for (const [key, value] of Object.entries(this.form)) {
                    if(value == null){
                        this.form[key] = "";
                    }
                }                
                if(this.form.start_date == "0000-00-00" || !this.form.start_date)
                    this.form.start_date = moment(String(new Date())).format('YYYY-MM-DD');
                
                if(this.form.end_date == "0000-00-00" || !this.form.end_date)
                    this.form.end_date = moment(String(new Date())).format('YYYY-MM-DD');

                //console.log(res.data);
                this.outlet_type_list = res.data.outletTypeList;
                this.status_list = res.data.statusList; 
                this.state_list = res.data.stateList; 

                
            }).catch((e)=>{
                //console.log(e.response);
                this.$refs.dlgMsg.showDlgBox(true,'error','View Outlet',e.response.data.error_message);
            })
            ;
        } catch(e) {
            //console.log(e);
            //this.$refs.dlgMsg.showDlgBox(true,'error','Supplier','Unknown Error');
        }
    },
    async save(){
        try{
            ApMerchantService.update_merchant_outlet(this.form).then(() => {
                this.$refs.dlgMsg.showDlgBox(true,'success','Update Outlet','Success.');
            }).catch((e)=>{
                //console.log(e.response);
                this.$refs.dlgMsg.showDlgBox(true,'error','Update Outlet',e.response.data.error_message);
            })
            ;
        } catch(e) {
            //console.log(e);
            //this.$refs.dlgMsg.showDlgBox(true,'error','Supplier','Unknown Error');
        }
    },
    
    uploadDone(e){
        //console.log(e);
        this.form.file_url = e.fileUrl;
        //console.log("this." + e.caller + "='" + e.fileName + "'");
        eval("this." + e.caller + "='" + e.fileName + "'");
        //this.form.logo_file_name = e.fileName;
        //console.log(e.caller);
    },

    showUpload(uploadType){
        //console.log('showupload');
        this.cpFiles.file_type = 'image';
        this.cpFiles.route_type = 'merchants';
        this.caller = uploadType;
        //console.log(uploadType)
        this.cpFiles.showDialog = true;
    },
    dlgClose(){
        this.visible = false;        
    },

    dlgImageMediaClose(selectedImage){
        this.dlgVisibleImageMedia = false;
        //console.log(selectedImage);

        // if(selectedImage.imageFileName && selectedImage.section == ""){
        //     this.form.logo_file_name = selectedImage.imageFileName
        //     this.form.file_url = selectedImage.fileUrl
        //     return
        // }

        this.form[selectedImage.section] = selectedImage.imageFileName;
        if(selectedImage.fileUrl)
            this.form.file_url = selectedImage.fileUrl;

        //console.log("form",this.form);
        // if(selectedImage.imageFileName && selectedImage.section =="items"){
        //     let e = {fileName:selectedImage.imageFileName,urlPreview:selectedImage.fileUrl}
        //     let totalImage = this.ciImages.length;
        //     let ciImageNew = {sequence:totalImage+1*10,file_url:e.urlPreview,image_file_name:e.fileName,preview:"",status:"ACTIVE"}
        //     this.ciImages.push(ciImageNew)
        //     this.saveIndicator = "(*)"
        //     //console.log(ciImageNew);

        // }
    },
    showDlgImageMedia(section){
        this.dlgImageMediaSection = section;
        this.dlgVisibleImageMedia = true;
        
        
    },

  }
}
</script>